import React from "react";
import { useStore } from "../store";
import { scoreColor } from "../utils";
import { DefaultScoreCard } from "./ScoreCard";
import useBenchmarkSelector from "./BenchmarkSelector";
import styled from "styled-components";
import { Title, BodyText, SmallTitle } from "../text";

const Container = styled.div`
  padding-top: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ScoreCardContainer = styled.div`
  flex: 1;
  border: 2px dashed #d8b4fe;
  padding: 1rem;
  margin-right: 1rem;
`;

const CoreScoreContainer = styled.div`
  flex: 1;
  margin-top: 2rem;
`;

const BenchmarkText = styled(BodyText)`
  margin-bottom: 1rem;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FlexEndContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-left: 2rem;
`;

const CategoryLabel = styled.span`
  display: block;
  margin-top: 0.5rem;
  text-align: center;
  margin-left: 2rem;
  width: 33.3%;
`;

const ScoreComposition = () => {
  const { exploreDataPageCsa, config } = useStore((state) => ({
    exploreDataPageCsa: state.exploreDataPageCsa,
    config: state.config,
  }));
  const getScore = (category: string) =>
    exploreDataPageCsa?.scoreTiers[category] || 0;
  const categories = config?.items
    .map(({ name }) => name)
    .filter((category) => getScore(category) !== undefined);
  const [benchmark, BenchmarkSelector] = useBenchmarkSelector({
    benchmarks: ["Nation", "State", "Region"],
  });

  if (!exploreDataPageCsa?.scoreTiers) {
    return null;
  }

  return (
    <Container>
      <Title>Overall Score Composition</Title>
      <FlexContainer>
        <ScoreCardContainer>
          {categories?.map((category) => (
            <DefaultScoreCard
              key={category}
              category={category}
              properties={exploreDataPageCsa}
            />
          ))}
        </ScoreCardContainer>
        <CoreScoreContainer>
          <SmallTitle>CORE Score Composition</SmallTitle>
          <BenchmarkText>Benchmark: {benchmark}</BenchmarkText>
          <RelativeContainer>
            <AbsoluteContainer>
              {[...Array(11)].map((_, i) => (
                <div key={i} className="flex items-center">
                  <span className="text-sm text-gray-600">{100 - i * 10}</span>
                  <div
                    className="flex-1"
                    style={{
                      borderTop: "1px solid black",
                      opacity: 0.2,
                    }}
                  />
                </div>
              ))}
            </AbsoluteContainer>
            <FlexEndContainer>
              {categories?.map((category) => (
                <div className="flex-1 relative" key={category}>
                  <div
                    style={{
                      backgroundColor: scoreColor(getScore(category)),
                      height: `${getScore(category) * 300}px`,
                    }}
                  />
                </div>
              ))}
            </FlexEndContainer>
          </RelativeContainer>
          <div className="flex justify-around">
            {categories?.map((category) => (
              <CategoryLabel key={category}>{category}</CategoryLabel>
            ))}
          </div>
          {BenchmarkSelector}
        </CoreScoreContainer>
      </FlexContainer>
    </Container>
  );
};

export default ScoreComposition;
