import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import { useApiRequest } from "../network";
import NavBar from "../components/NavBar";
import SidePanel from "../components/SidePanel";
import geojsonUrl from "../data/csas.geojson";
import geojsonCbsaUrl from "../data/cbsas.geojson";
import { useStore } from "../store";
import Dive from "./Dive";
import { getCentroid } from "../utils";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

interface PanelProps {
  isOpen: boolean;
  isWide?: boolean;
}

const SidePanelContainer = styled.div<PanelProps>`
  width: 330px;
  transition: transform 0.3s ease-in-out;
`;

const BottomPanel = styled.div<PanelProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  height: ${(props) => (props.isOpen ? "calc(100vh - 60px)" : "80vh")};
  width: calc(100% - 316px);
  background-color: white;
  transform: translateY(${(props) => (props.isOpen ? "0" : "100%")});
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MapContainer = styled.div<PanelProps>`
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
  width: ${(props) => (props.isWide ? "100%" : "calc(100% - 300px)")};
`;

export default function HomePage() {
  const { csaId } = useParams<{ csaId: string }>();
  const {
    geojsonData,
    setGeojsonData,
    exploreDataPageCsa,
    setConfig,
    setGeojsonCbsaData,
    geojsonCbsaData,
    mapRef,
    setExploreDataPage,
  } = useStore();
  const [isBottomPanelOpen, setBottomPanelOpen] = useState(false);

  const makeApiRequest = useApiRequest();

  useEffect(() => {
    makeApiRequest({
      method: "GET",
      endpoint: "/tiered-config",
      onSuccess: setConfig,
      hideSuccessSnackbar: true,
    });
  }, []);

  useEffect(() => {
    const loadGeoJsonData = async () => {
      try {
        const response = await fetch(geojsonUrl);
        const data = await response.json();
        setGeojsonData(data);
        console.log("GeoJSON data loaded");
      } catch (error) {
        console.error("Failed to load GeoJSON data:", error);
      }
    };
    const loadCbsaGeoJsonData = async () => {
      try {
        const response = await fetch(geojsonCbsaUrl);
        const data = await response.json();
        setGeojsonCbsaData(data);
        console.log("GeoJSON CBSA data loaded");
      } catch (error) {
        console.error("Failed to load GeoJSON CBSA data:", error);
      }
    };
    loadGeoJsonData();
    loadCbsaGeoJsonData();
  }, [setGeojsonData, setGeojsonCbsaData]);

  useEffect(() => {
    if (csaId && geojsonData) {
      const csaData = geojsonData.features.find(
        (feature) => feature.properties.csaCode === csaId
      )?.properties;
      if (csaData) {
        setExploreDataPage(csaData);
      }
    }
  }, [csaId, geojsonData, setExploreDataPage]);

  useEffect(() => {
    const open = !!exploreDataPageCsa;
    setBottomPanelOpen(open);
    if (open) {
      const ourFeature = geojsonData?.features.find(
        (feature) => feature.properties.csaCode === exploreDataPageCsa?.csaCode
      );
      if (mapRef && !!ourFeature) {
        const center = getCentroid(ourFeature);
        if (center.every((coord) => coord !== 0 && !isNaN(coord))) {
          mapRef.flyTo({
            center,
            zoom: 6,
            speed: 0.8,
            curve: 1,
          });
        }
      }
    }
  }, [!!exploreDataPageCsa, geojsonData, mapRef, getCentroid]);

  return (
    <PageContainer>
      <NavBar />
      <ContentContainer>
        <SidePanelContainer isOpen={true}>
          <SidePanel />
        </SidePanelContainer>
        <MapContainer isOpen={true} isWide={isBottomPanelOpen}>
          {!isBottomPanelOpen && geojsonData && geojsonCbsaData && <Map />}
        </MapContainer>
      </ContentContainer>
      <BottomPanel isOpen={isBottomPanelOpen}>
        <Dive />
      </BottomPanel>
    </PageContainer>
  );
}
