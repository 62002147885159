import React from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SliderLabel = styled.span`
  margin: 0 10px;
  font-weight: bold;
`;

const SliderInput = styled.input`
  -webkit-appearance: none;
  width: 80px;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 5px;
  margin: 0 10px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #2196f3;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #2196f3;
    cursor: pointer;
    border-radius: 50%;
  }
`;

const Slider = ({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
}) => (
  <SliderContainer>
    <SliderLabel onClick={() => onChange(!value)}>CSA</SliderLabel>
    <SliderInput
      type="range"
      min="0"
      max="1"
      step="1"
      value={value ? 1 : 0}
      onChange={(e) => onChange(e.target.value === "1")}
    />
    <SliderLabel onClick={() => onChange(!value)}>CBSA</SliderLabel>
  </SliderContainer>
);

export default Slider;
