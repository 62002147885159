import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Tab, useStore, useFlatConfig } from "../store";
import TitleBox from "./TitleBox";
import Explore from "./Explore";
import Histogram from "./Histogram";

const SidePanelContainer = styled.div`
  background-color: white;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  height: calc(100vh - 60px);
`;

const TabNavigation = styled.div`
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  padding: 0.25rem 1rem;
  font-size: 1rem;
  font-weight: medium;
  border-bottom-width: 2px;
  border-color: ${(props) => (props.isActive ? "black" : "transparent")};
  color: ${(props) => (props.isActive ? "black" : "#717171")};
`;

const ContentArea = styled.div`
  padding: 1rem;
`;

const HistogramContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default function SidePanel() {
  const navigate = useNavigate();
  const { currentCSAData, activeTab, setActiveTab, mapMetric } = useStore(
    (state) => ({
      currentCSAData: state.currentCSAData,
      activeTab: state.activeTab,
      setActiveTab: state.setActiveTab,
      mapMetric: state.mapMetric,
    })
  );
  const csaData = currentCSAData || null;

  const tabs: Tab[] = ["Explore", "Inspect", "Compare"];
  const csaSelected = !!csaData?.scores?.overall;
  const flatConfig = useFlatConfig();
  const mapMetricLevel = flatConfig[mapMetric]?.level;

  const handleExploreData = () => {
    if (csaData) {
      navigate(`/explore/csa/${csaData.csaCode}`);
    }
  };

  return (
    <SidePanelContainer>
      <TabNavigation>
        {tabs.map((tab) => (
          <TabButton
            key={tab}
            onClick={() => setActiveTab(tab)}
            isActive={activeTab === tab}
          >
            {tab}
          </TabButton>
        ))}
      </TabNavigation>
      <div>
        <TitleBox />
      </div>
      <ContentArea>
        {activeTab === "Compare" ? <p>Coming Soon</p> : <Explore />}
      </ContentArea>
      <HistogramContainer>
        {["Explore", "Inspect"].includes(activeTab) && (
          <Histogram
            numBuckets={mapMetricLevel === "subindicator" ? 2000 : 50}
          />
        )}
      </HistogramContainer>
      {csaSelected && <button onClick={handleExploreData}>Explore data</button>}
      <br />
    </SidePanelContainer>
  );
}
