import React from "react";
import RadarChart from "../components/RadarChart";
import Drilldown from "../components/Drilldown";
import CoreDrivers from "../components/CoreDrivers";
import ScoreComposition from "../components/ScoreComposition";
import PerformanceScatterChart from "../components/PerformanceScatterChart";
import styled from "styled-components";
import { useStore } from "../store";
import CoreScore from "../components/CoreScore";

const SubNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Section = styled.div`
  height: 80vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  scroll-snap-align: center;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  border: 1px dotted lightgray;
`;

const Container = styled.div`
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100vh;
`;

export default function Dive() {
  const { setExploreDataPage, exploreDataPageCsa, config } = useStore(
    (state) => ({
      setExploreDataPage: state.setExploreDataPage,
      exploreDataPageCsa: state.exploreDataPageCsa,
      config: state.config,
    })
  );

  return (
    <Container>
      <SubNavBar>
        <div>{exploreDataPageCsa?.name}</div>
        <button
          onClick={() => {
            setExploreDataPage(null);
          }}
        >
          Go Back
        </button>
      </SubNavBar>
      <div>
        <Section>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <CoreScore />
            <RadarChart />
          </div>
        </Section>
        <Section>
          <ScoreComposition />
        </Section>
        <Section>
          <PerformanceScatterChart />
        </Section>
        {config?.items.map((item, i) => (
          <Section key={i}>
            <Drilldown category={item.name} />
          </Section>
        ))}
        <Section>
          <CoreDrivers />
        </Section>
      </div>
    </Container>
  );
}
