import React from "react";
import { useStore } from "../store";
import { PolarArea } from "react-chartjs-2";
import {
  Chart,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { scoreColor, hexToRgba } from "../utils";
import styled from "styled-components";

Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

interface PolarAreaData {
  labels: string[];
  datasets: {
    label: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    data: number[];
  }[];
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const PolarAreaChartInner = ({ data }: { data: PolarAreaData }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: { beginAtZero: true },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <ChartContainer>
      <PolarArea data={data} options={options as any} />
    </ChartContainer>
  );
};

const PolarAreaChart = () => {
  const { config, exploreDataPageCsa } = useStore();
  if (!config || !exploreDataPageCsa) return null;

  const labels = config.items
    .map(({ items }) => items.map((i) => i.name))
    .flat();
  const data: PolarAreaData = {
    labels,
    datasets: config.items.map(({ name, items }) => ({
      label: name,
      backgroundColor: hexToRgba(
        scoreColor(exploreDataPageCsa.scoreTiers[name]),
        0.2
      ),
      borderColor: scoreColor(exploreDataPageCsa.scoreTiers[name]),
      borderWidth: 1,
      data: labels.map(
        (label) =>
          (items.map((i) => i.name).includes(label)
            ? exploreDataPageCsa.scoreTiers[label] || 0
            : 0) * 100
      ),
    })),
  };

  return <PolarAreaChartInner data={data} />;
};

export default PolarAreaChart;
