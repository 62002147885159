import React from "react";

const ScorePill = ({ label, color }: { label: string; color: string }) => (
  <span
    className={`px-2 py-1 text-white text-center`}
    style={{
      backgroundColor: color,
      width: 90,
      fontFamily: "DM Sans; sans-serif",
      fontWeight: "bold",
    }}
  >
    {label}
  </span>
);

export default ScorePill;
