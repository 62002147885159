import React, { useEffect } from "react";
import styled from "styled-components";
import { useStore } from "../store";
import Map from "./Map";
import { getCentroid, getCategoryDescription } from "../utils";
import { DefaultScoreCard } from "./ScoreCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "DM Sans", serif;
  margin-bottom: 0.5rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IndicatorsSection = styled.div`
  width: 50%;
  padding-right: 20px;
`;

const MapSection = styled.div`
  width: 40%;
  height: 400px;
  border: 1px dotted #ccc;
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #4a4a4a;
`;

const Drilldown = ({ category }: { category: string }) => {
  const { exploreDataPageCsa, geojsonData, config } = useStore((state) => ({
    exploreDataPageCsa: state.exploreDataPageCsa,
    geojsonData: state.geojsonData,
    config: state.config,
  }));
  if (!exploreDataPageCsa) {
    return;
  }
  const ourFeature = geojsonData?.features.find(
    (feature) => feature.properties.csaCode === exploreDataPageCsa?.csaCode
  );

  const subcategories =
    config?.items.find((item) => item.name === category)?.items || [];

  return (
    <Container>
      <Header>
        <Title>{category}</Title>
      </Header>
      <Content>
        <IndicatorsSection>
          <Subtitle>{getCategoryDescription(category)}</Subtitle>
          {subcategories.map((subcategory) => (
            <DefaultScoreCard
              key={subcategory.name}
              category={subcategory.name}
              properties={exploreDataPageCsa}
            />
          ))}
        </IndicatorsSection>
        <MapSection>
          <Map
            customMapMetric={category}
            customCenter={ourFeature && getCentroid(ourFeature)}
            zoom={5.5}
          />
        </MapSection>
      </Content>
    </Container>
  );
};

export default Drilldown;
