import styled from "styled-components";

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  font-family: "DM Sans", serif;
`;

export const SmallTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
`;

export const BodyText = styled.p`
  font-size: 0.875rem;
  color: #4a4a4a;
`;
