import React, { useState } from "react";
import { useStore } from "../store";
import styled from "styled-components";
import FoldUnfoldButton from "./FoldUnfoldButton";
import { scoreColor } from "../utils";

const CategoryItem = styled.div`
  text-align: left;
  margin-top: 4px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const CategoryName = styled.p<{ isActive: boolean }>`
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#178ab0" : "black")};
`;

const SubCategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const ScoreBox = styled.div`
  border-radius: 50%;
  min-width: 12px;
  min-height: 12px;
  height: 12px;
  width: 12px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export default function Explore() {
  const { config, domainTab } = useStore((state) => ({
    config: state.config,
    domainTab: state.domainTab,
  }));
  const [unfoldedCategories, setUnfoldedCategories] = useState<Set<string>>(
    new Set()
  );

  const toggleUnfold = (name: string) => {
    setUnfoldedCategories((prevUnfoldedCategories) => {
      const newUnfolded = new Set(prevUnfoldedCategories);
      if (newUnfolded.has(name)) {
        newUnfolded.delete(name);
      } else {
        newUnfolded.add(name);
      }
      return newUnfolded;
    });
  };

  const isUnfolded = (name: string) => unfoldedCategories.has(name);

  const categoryConfig =
    domainTab === "Overall"
      ? config
      : config?.items.find((item) => item.name === domainTab);
  if (!categoryConfig) {
    return null;
  }

  return (
    <div>
      {categoryConfig.items.map(({ name, items, weight }) => (
        <CategoryItem key={name}>
          <CategorySection
            name={name}
            weight={weight}
            allowUnfold={true}
            isUnfolded={isUnfolded(name)}
            toggleUnfold={toggleUnfold}
          />
          {isUnfolded(name) && (
            <SubCategory>
              {items.map((sub) => (
                <CategorySection
                  name={sub.name}
                  weight={sub.weight}
                  allowUnfold={false}
                  isUnfolded={isUnfolded(sub.name)}
                  toggleUnfold={toggleUnfold}
                />
              ))}
            </SubCategory>
          )}
        </CategoryItem>
      ))}
    </div>
  );
}

function CategorySection({
  name,
  weight,
  allowUnfold,
  isUnfolded,
  toggleUnfold,
}: {
  name: string;
  weight: number;
  allowUnfold: boolean;
  isUnfolded: boolean;
  toggleUnfold: (name: string) => void;
}) {
  const { activeTab, currentCSAData, mapMetric, setMapMetric } = useStore(
    (state) => ({
      activeTab: state.activeTab,
      currentCSAData: state.currentCSAData,
      mapMetric: state.mapMetric,
      setMapMetric: state.setMapMetric,
    })
  );
  const csaData = activeTab === "Inspect" ? currentCSAData : null;
  const extraInfo = (name: string, weight: number) => {
    if (!csaData) {
      return null;
    }
    const key = name === "Overall" ? "overall" : name;
    const score = csaData.scores[key];
    const scoreTier = csaData.scoreTiers[key]
      ? csaData.scoreTiers[key] * 100
      : null;

    return (
      <p className="text-xs ml-4">
        {score?.toPrecision(3)}, {scoreTier?.toPrecision(2)}%,{" "}
        {Math.abs(weight)}
      </p>
    );
  };

  const box = (name: string) => {
    if (!csaData) {
      return null;
    }
    const key = name === "Overall" ? "overall" : name;
    const scoreTier = csaData.scoreTiers[key];
    return <ScoreBox color={scoreColor(scoreTier)} />;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {box(name)}
        <CategoryName
          isActive={name === mapMetric}
          onClick={() => setMapMetric(name)}
        >
          {name}
        </CategoryName>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {extraInfo(name, weight)}
        {allowUnfold && (
          <FoldUnfoldButton
            unfolded={isUnfolded}
            toggleUnfold={() => toggleUnfold(name)}
          />
        )}
      </div>
    </div>
  );
}
