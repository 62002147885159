import { CsaProperties } from "../store";
import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useStore } from "../store";
import { calculatePercentiles, scoreColor } from "../utils";
import styled from "styled-components";
import useBenchmarkSelector from "./BenchmarkSelector";
import { Title, BodyText } from "../text";

export function getRiskAdjustedClimateRisk(p: CsaProperties) {
  return {
    risk:
      p.scoreTiers["Hazard Insulation"] +
      p.scoreTiers["Path to Sustainability"],
    capacity:
      p.scoreTiers["Baseline Resilience"] + p.scoreTiers["Growth Potential"],
  };
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, percentileRisk, capacity } = payload[0].payload;
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <p className="font-semibold">{name}</p>
        <p className="intro">{`Resilience-Adjusted Climate Risk: ${Math.round(
          percentileRisk
        )}%`}</p>
        <p className="intro">{`Growth Potential: ${Math.round(capacity)}%`}</p>
      </div>
    );
  }

  return null;
};

const renderCustomShape = ({ cx, cy, payload }: any) => {
  const radius = payload.isSelected ? 10 : 5;
  const fill = scoreColor(payload.combinedScore);
  return (
    <circle
      cx={cx}
      cy={cy}
      r={radius}
      fill={fill}
      style={{
        zIndex: payload.isSelected ? 100 : 0,
        opacity: payload.isSelected ? 1 : 0.35,
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TextSection = styled.div`
  width: 30%;
  padding-right: 20px;
`;

const ChartSection = styled.div`
  width: 70%;
`;

const Subtitle = styled(BodyText)`
  margin-top: 20px;
`;

const ScatterPlot = () => {
  const { geojsonData, exploreDataPageCsa } = useStore((state) => ({
    geojsonData: state.geojsonData,
    exploreDataPageCsa: state.exploreDataPageCsa,
  }));
  const [benchmark, BenchmarkSelector] = useBenchmarkSelector({
    benchmarks: ["Nation", "State", "Region"],
  });

  if (!geojsonData || !exploreDataPageCsa) {
    return null;
  }

  const data = geojsonData.features.map((feature) => {
    const { risk, capacity } = getRiskAdjustedClimateRisk(feature.properties);
    return {
      risk,
      capacity,
      name: feature.properties.name,
      csaCode: feature.properties.csaCode,
    };
  });

  const risks = data.map((d) => d.risk);
  const percentileRisks = calculatePercentiles(risks);

  const transformedData = data.map((d, i) => ({
    percentileRisk: percentileRisks[i] * 100,
    capacity: d.capacity * 100,
    name: d.name,
    csaCode: d.csaCode,
    combinedScore: (d.capacity + percentileRisks[i]) / 2,
    isSelected: d.csaCode === exploreDataPageCsa.csaCode,
  }));

  return (
    <Container>
      <TextSection>
        <Title>Overall Performance</Title>
        <p>
          The CORE score is calculated by adjusting climate risk with baseline
          resilience and combining with growth percentile score.
        </p>
        <Subtitle>Benchmark Resolution</Subtitle>
        {BenchmarkSelector}
      </TextSection>
      <ChartSection>
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart margin={{ top: 60, right: 30, bottom: 40, left: 80 }}>
            <text
              x={60}
              y={20}
              textAnchor="start"
              dominantBaseline="central"
              style={{ fontWeight: "bold" }}
            >
              CORE Performance:
            </text>
            <text x={220} y={26} style={{ fontWeight: "normal" }}>
              Resilience-Adjusted Climate Risk
            </text>
            <text
              x={60}
              y={40}
              textAnchor="start"
              dominantBaseline="central"
              style={{ fontStyle: "italic" }}
            >
              Benchmark: {benchmark}
            </text>
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="capacity"
              name="Growth Potential"
              unit="%"
              label={{
                value: "Growth Potential (%)",
                position: "insideBottom",
                offset: -20,
              }}
            />
            <YAxis
              type="number"
              dataKey="percentileRisk"
              name="Resilience-Adjusted Climate Risk"
              unit="%"
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ strokeDasharray: "3 3" }}
            />
            <Scatter
              name="CSAs"
              data={transformedData}
              shape={renderCustomShape}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </ChartSection>
    </Container>
  );
};

export default ScatterPlot;
