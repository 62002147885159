import React from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar";

const PageContainer = styled.div`
  display: flex;
  flexdirection: column;
  height: 100vh;
`;

const LoginPage = () => {
  return (
    <PageContainer>
      <NavBar />
    </PageContainer>
  );
};

export default LoginPage;
