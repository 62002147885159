import React, { useState } from "react";

export default function useBenchmarkSelector({
  benchmarks,
}: {
  benchmarks: string[];
}): [string, JSX.Element] {
  const [benchmark, setBenchmark] = useState(benchmarks[0]);
  return [
    benchmark,
    <div className="mt-4">
      <select
        value={benchmark}
        onChange={(e) => setBenchmark(e.target.value)}
        className="border border-gray-300 rounded p-2 w-full"
      >
        {benchmarks.map((b) => (
          <option key={b} value={b}>
            {b}
          </option>
        ))}
      </select>
    </div>,
  ];
}
