import React from "react";
import { scoreColor } from "../utils";
import { useStore } from "../store";

export default function Histogram({ numBuckets = 100 }) {
  const { geojsonData, currentCSAData, mapMetric } = useStore((state) => ({
    geojsonData: state.geojsonData,
    currentCSAData: state.currentCSAData,
    mapMetric: state.mapMetric,
  }));
  if (!geojsonData || !mapMetric) {
    return null;
  }

  const key = mapMetric === "Overall" ? "overall" : mapMetric;
  const relevantScores = geojsonData.features
    .map((feature) => feature.properties.scores[key])
    .filter((x): x is number => !isNaN(x));

  const minScore = Math.min(...relevantScores);
  const maxScore = Math.max(...relevantScores);
  const bucketSize = (maxScore - minScore) / numBuckets;

  const buckets = Array.from({ length: numBuckets }, (_, i) => ({
    min: minScore + i * bucketSize,
    max: minScore + (i + 1) * bucketSize,
    count: 0,
  }));

  const getBucketIndexForScore = (score: number) =>
    Math.min(Math.floor((score - minScore) / bucketSize), numBuckets - 1);
  relevantScores.forEach((score) => {
    buckets[getBucketIndexForScore(score)].count += 1;
  });

  const maxCount = Math.max(...buckets.map((bucket) => bucket.count));
  const chartWidth = 300;
  const barWidth = chartWidth / numBuckets;
  const chartHeight = 200; // Adjust as needed

  return (
    <svg width={chartWidth} height={chartHeight}>
      {buckets.map((bucket, index) => {
        const barHeight = (bucket.count / maxCount) * chartHeight;
        return (
          <rect
            key={index}
            x={index * barWidth}
            y={chartHeight - barHeight}
            width={barWidth} // Leave small space between bars
            height={barHeight}
            fill={
              currentCSAData?.scores[key] !== undefined &&
              getBucketIndexForScore(currentCSAData.scores[key]) === index
                ? scoreColor(currentCSAData.scoreTiers[key])
                : "#9ea1a3"
            }
          />
        );
      })}
    </svg>
  );
}
