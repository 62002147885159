import React from "react";
import { DomainTab, DomainTabValues, CsaProperties, useStore } from "../store";
import styled from "styled-components";

export default function TitleBox() {
  const {
    activeTab,
    domainTab,
    setDomainTab,
    setMapMetric,
    mapMetric,
    currentCSAData,
  } = useStore((state) => ({
    activeTab: state.activeTab,
    domainTab: state.domainTab,
    setDomainTab: state.setDomainTab,
    setMapMetric: state.setMapMetric,
    mapMetric: state.mapMetric,
    currentCSAData: state.currentCSAData,
  }));
  const csaData = currentCSAData;
  const domainTabs: DomainTab[] = Object.values(DomainTabValues);
  if (activeTab === "Compare") {
    return null;
  }

  return (
    <Container>
      <Title>
        {activeTab === "Explore" && <ExploreTitle domainTab={domainTab} />}
        {activeTab === "Inspect" && <InspectTitle csaData={csaData || null} />}
      </Title>
      <TabContainer>
        {domainTabs.map((tab: DomainTab) => (
          <TabButton
            key={tab}
            onClick={() => {
              setDomainTab(tab);
              setMapMetric(tab);
            }}
            isActive={tab === mapMetric}
            selectedCurrently={domainTab === tab}
          >
            {tab}
          </TabButton>
        ))}
      </TabContainer>
    </Container>
  );
}

const ExploreTitle: React.FC<{ domainTab: DomainTab }> = ({ domainTab }) => (
  <div>
    <h3 className="font-semibold">
      {domainTab === "Overall" ? "CORES" : domainTab}
    </h3>
    <p style={{ marginTop: 4, marginBottom: 4 }}>
      {domainTab === "Overall"
        ? "Index combining data related to climate risk exposure, baseline resilience, and growth potential."
        : "Index combining data related to climate risk exposure, baseline resilience, and growth potential."}
    </p>
  </div>
);

const InspectTitle: React.FC<{ csaData: CsaProperties | null }> = ({
  csaData,
}) => (
  <div>
    {csaData?.name && <p className="font-semibold">{csaData.name}</p>}
    <div style={{ marginTop: 4, marginBottom: 4 }}>
      <p className="text-xs">CSA</p>
      <p className="text-xs">Benchmark: Nation</p>
    </div>
  </div>
);

const Container = styled.div``;

const Title = styled.h2`
  text-align: left;
  margin: 12px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
`;

interface TabButtonProps {
  isActive: boolean;
  selectedCurrently: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 2px 8px;
  font-size: x-small;
  border-bottom: 2px solid;
  border-color: ${(props) =>
    props.selectedCurrently ? "black" : "transparent"};
  color: ${(props) =>
    props.isActive ? "#178ab0" : props.selectedCurrently ? "black" : "gray"};
`;
