import React from "react";
import styled from "styled-components";

interface FoldUnfoldButtonProps {
  unfolded: boolean;
  toggleUnfold: () => void;
}

const ButtonContainer = styled.div`
  cursor: pointer;
`;

export default function FoldUnfoldButton({
  unfolded,
  toggleUnfold,
}: FoldUnfoldButtonProps) {
  return (
    <ButtonContainer onClick={toggleUnfold}>
      {unfolded ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      )}
    </ButtonContainer>
  );
}
